import adultTraining from "@static/images/campaigns/adultTraining.jpg"
import youthWorkshops from "@static/images/campaigns/youthWorkshops.jpg"

const calloutTextBlocks = [
  {
    heading: "Social inclusion",
    body:
      "We tackle social isolation by creating spaces where LGBTQIA+ young people belong, are celebrated and can make friends.",
  },
  {
    heading: "Education & advocacy",
    body:
      "To create an Australia free from discrimination we deliver LGBTQIA+ training, resources, and digital campaigns.",
  },
  {
    heading: "Youth empowerment",
    body:
      "We equip young people with the skills and opportunities they need to be at the forefront of driving change.",
  },
]

const imageTextBlocks = [
  {
    image: youthWorkshops,
    heading: "Visibility merch",
    text:
      "Show your pride and drive inclusion - Minus18 rainbow merch makes a difference. Literally! 100% of your purchase supports youth programs and events.",
    links: [
      {
        link: "/shop/pride",
        label: "Pride",
      },
      {
        link: "/shop/school",
        label: "Schools",
      },
      {
        link: "/shop/workplace",
        label: "Workplaces",
      },
    ],
  },
  {
    image: adultTraining,
    heading: "Workshops & training",
    text:
      "Our highly experienced presenters head into schools and workplaces to deliver LGBTQIA+ inclusion training, keynotes, and workshops. Customisable for all knowledge levels.",
    links: [
      {
        link: "/workshops/schools",
        label: "School workshops",
      },
      {
        link: "/workshops/workplace",
        label: "Workplace training",
      },
    ],
  },
]

export default { calloutTextBlocks, imageTextBlocks }
